import React from 'react'
import styled from '@emotion/styled'

// Components
import Paragraph1 from '../atoms/paragraph-1';
import Header2 from '../atoms/header-2';

// Images
import ImgBannerSOTB from '../images/_banner/img-banner-sotb';

// Styles
const Container = styled.section`
  position: relative;
  display: flex;
  width: 1100px;
  height: 300px;
`
const Background = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
`
const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px;
`

const BannerThankYou = () => (
  <Container>
    <Background>
      <ImgBannerSOTB/>
    </Background>

    <ContainerInfo>
      <Header2 color={'#FFFFFF'} fontSize={'2.5em'} fontWeight={'300'} spacing={'0px'}>
        Thank You For Your Submission!
      </Header2>

      <Paragraph1 color='#FFFFFF' fontSize={'1.5em'}>
        You will be redirected in just a moment...
      </Paragraph1>
    </ContainerInfo>
  </Container>
)

export default BannerThankYou