import React from "react"

// Components
import MediaQuery from 'react-responsive'
import Layout from "../components/layout"
import SEO from "../components/organisms/seo"
import Masthead from "../components/organisms/masthead"
import Footer from "../components/organisms/footer"

// Images
import ImgMastheadHome from "../components/images/_masthead/img-masthead-home"
import BannerThankYou from "../components/molecules/banner-thank-you";

class ThankYouPage extends React.Component {
  componentDidMount() {
    this.mounted = true
    setTimeout(() => {
      if(this.mounted) {
        if(typeof window !== 'undefined') {
          window.history.back()
        }
      }
    }, 5000) 
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    return (
      <Layout>
        <SEO title="Thank You" keywords={[`beverly hubbard`, `beverly`, `hubbard`, `christian author`, `christian`, `author`, `the story of the bible`, `book writer`, `christian book author`, `home`]} />
        
        <MediaQuery query="(min-width: 768px)">
          <Masthead 
            image={<ImgMastheadHome/>}
            large={false}
          >
            <BannerThankYou/>
          </Masthead>
        </MediaQuery>

        <MediaQuery query="(max-width: 767px)">
          <Masthead 
            image={<ImgMastheadHome/>}
            large={true}
          >
            <BannerThankYou/>
          </Masthead>
        </MediaQuery>

        <Footer/>

      </Layout>
    )
  }
}

export default ThankYouPage
